<template>
  <component v-if="category" :is="pageTemplate" :key="key"></component>
  <not-found v-else />
</template>
<script>
import split from "lodash/split";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryTemplate from "@/mixins/categoryTemplate";
import NotFound from "@/components/category/not-found.vue";

export default {
  name: "Category",
  mixins: [categoryMixins, deliveryReactive, categoryTemplate],
  components: [NotFound],
  data() {
    return {
      key: 1
    };
  },
  methods: {
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  }
};
</script>
